/* SettingsAdmin.css */
.SettingsAdmin {
  position: relative;
  width: 100%;
}

.SettingsAdmin__Title {
  position: absolute;
  top: -57px;
}

.SettingsAdmin__Title-Text {
  color: #0c2c52;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin: 0;
}

.SettingsAdmin__Content {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #a1b4c4;
}

.SettingsAdmin__Elements {
  background-color: #a1b4c4;
  display: grid;
  grid-template-columns: minmax(150px, 1fr) minmax(150px, 2fr);
  gap: 1px;
  padding-bottom: 1px;
}

.SettingsAdmin__Element {
  padding: 19px 27px;
  background-color: #fff;
}

.SettingsAdmin__Element > p {
  line-height: 18px;
  font-size: 15px;
  margin: 0;
}

@media (max-width: 768px) {
  .SettingsAdmin__Elements {
    grid-template-columns: 1fr;
  }
}
