.UserSettings {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.UserSettings__menu-container {
  width: 268px;
}

.UserSettings__menu {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: 170px;
  width: 268px;
  height: 100vh;
  border-right: 1px solid #a1b4c4;
  background-color: #fff;
}

.UserSettings__menu-item-first {
  border-top: 1px solid #a1b4c4;
}

.UserSettings__content {
  flex: 3.8;
  padding: 60px 70px;
  overflow: auto;
  max-height: calc(100vh - 111px);
}

.UserSettings__title {
  position: absolute;
  top: -57px;
  left: 0;
  margin-left: 20px;
  z-index: 20;
}

.UserSettings__title-text {
  font-size: 25px;
  color: #0c2c52;
  font-weight: 700;
}
