.ItemHistoryEntry {
  height: 53px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #0c2c52;
  border-bottom: 1px solid #d8d8d8;
  position: relative;
}

.ItemHistoryEntry__information {
}

.ItemHistoryEntry__information-date {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.ItemHistoryEntry__information-location {
  font-size: 11px;
  line-height: 12px;
  color: #6d7a85;
}

.ItemHistoryEntry__amount {
  height: 100%;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  justify-content: flex-end;
  margin-left: auto;
  width: 135px;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}

.ItemHistoryEntry__amount-number {  
  font-weight: 700;
  font-size: 15px;
  width: 100%;
}

.ItemHistoryEntry__undo {
  padding: 0 15px;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 250ms ease-in-out;
}

.ItemHistoryEntry__undo:hover {
  background-color: rgba(64, 146, 215, 0.1);
  transition: background-color 250ms ease-in-out;
}

.ItemHistoryEntry__undo:hover > div > div > svg > g > g {
  fill: #4092d7;
  transition: all 250ms ease-in-out;
}

.ItemHistoryEntry__undo__hidden {
  opacity: 0.3;
}

.ItemHistoryEntry__undo__hidden:hover {
  background-color: transparent;
}

.ItemHistoryEntry__undo__hidden:hover > div > div > svg > g > g {
  fill: #a1b4c4;
}

.ItemHistoryEntry__information-revertTime {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 2px 7px 2px;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  text-align: right;
}

.ItemHistoryEntry__information-revertTime-icon {
  margin-right: 3px;
  transform: translateY(-1px);
}

.ItemHistoryEntry {
  position: relative;
}

.ItemHistoryEntry__connection-line {
  position: absolute;
  left: -20px;
  width: 2px;
  background-color: #a1b4c4;
  opacity: 0.5;
  pointer-events: none;
  z-index: 1;
}

.ItemHistoryEntry__connection-dot {
  position: absolute;
  left: -16px;
  top: 50%;
  width: 8px;
  height: 8px;
  background-color: #a1b4c4;
  border-radius: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

/* Add specific styles for different event types */
.ItemHistoryEntry--ordered .ItemHistoryEntry__connection-dot {
  background-color: #0c2c52;
}

.ItemHistoryEntry--received .ItemHistoryEntry__connection-dot {
  background-color: #28a745;
}