/* SettingsAdminContent.css */
.SettingsAdminContent {
  position: relative;
  width: 100%;
}

.SettingsAdminContent__Title {
  position: absolute;
  top: -57px;
}

.SettingsAdminContent__Title-Text {
  color: #0c2c52;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

.SettingsAdminContent__Content {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #a1b4c4;
}

.SettingsAdminContent__Elements {
  background-color: #a1b4c4;
  display: grid;
  grid-template-columns: minmax(150px, 1fr) minmax(150px, 2fr);
  gap: 1px;
}

.SettingsAdminContent__Element {
  background-color: #fff;
  padding: 19px 27px;
}

.SettingsAdminContent__Element > p {
  margin: 0 0 4px 0;
  font-size: 15px;
  line-height: 18px;
  color: #0c2c52;
  font-weight: 500;
}

.SettingsAdminContent__Element > label {
  color: #6d7a85;
  font-size: 11px;
  line-height: 13px;
  display: block;
}

.SettingsAdminContent__actions {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .SettingsAdminContent__Elements {
    grid-template-columns: 1fr;
  }

  .SettingsAdminContent__actions {
    flex-direction: column;
  }

  .SettingsAdminContent__actions button {
    width: 100%;
  }
}
