/* SettingsDataRetrievalContent.css */
.SettingsDataRetrieval__Title {
  position: absolute;
  top: -57px;
}

.SettingsDataRetrieval__Title-Text {
  color: #0c2c52;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

.SettingsDataRetrieval__Content {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #a1b4c4;
}

.SettingsDataRetrieval__Elements {
  background-color: #a1b4c4;
  display: grid;
  grid-template-columns: minmax(150px, 1fr) minmax(150px, 2fr);
  gap: 1px;
  padding-bottom: 1px;
}

.SettingsDataRetrieval__Element {
  padding: 19px 27px;
  background-color: #fff;
}

.SettingsDataRetrieval__Element > p {
  line-height: 18px;
  font-size: 15px;
}

.SettingsDataRetrieval__Element > label {
  color: #6d7a85;
  font-size: 11px;
  line-height: 13px;
}

.SettingsDataRetrieval__dates {
  background-color: #fff;
  padding: 19px 27px;
}

.SettingsDataRetrieval__date-picker {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.SettingsDataRetrieval__date-picker label {
  color: #6d7a85;
  font-size: 11px;
  line-height: 13px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  padding: 8px 12px;
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: #0c2c52;
}

.SettingsDataRetrieval__actions {
  background-color: #fff;
  padding: 19px 27px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

/* Responsive design */
@media (max-width: 768px) {
  .SettingsDataRetrieval__Elements {
    grid-template-columns: 1fr;
  }

  .SettingsDataRetrieval__dates {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .SettingsDataRetrieval__actions {
    flex-direction: column;
  }

  .SettingsDataRetrieval__actions button {
    width: 100%;
  }
}
